@import "~@ng-select/ng-select/themes/default.theme.css";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

body,
* {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

button {
  cursor: pointer;
}

.ng-select.filter {
  .ng-placeholder {
    padding: 12px;
  }

  .ng-select-container {
    padding: 12px;
    border: 1px solid #cfd4d9;
    min-height: 24px;
  }

  &.ng-select-single .ng-select-container {
    height: auto;
    min-height: 24px;

    .ng-placeholder {
      padding: 0;
    }

    .ng-input {
      transform: translateY(50%);
    }
  }

  .ng-value {
    * {
      background-color: white;
      color: black;
    }
  }

  &.ng-select-searchable .ng-placeholder::before {
    content: url(/assets/icon-search.svg);
    width: 16px;
    height: 16px;
    padding-right: 10px;
  }

  .ng-option {
    font-size: 16px;
  }
}

.table-view {
  th {
    text-align: left;
  }

  tr {
    height: 40px;
  }

  td {
    padding-left: 24px !important;
  }

  th {
    padding-left: 24px !important;
  }

  .table-row {
    .status::before {
      content: " ";

      border-radius: 100%;
      width: 12px;
      height: 12px;
      margin-right: 8px;
      display: inline-block;
    }
    .new::before {
      background-color: black;
    }
    .active::before {
      background-color: #006fba;
    }
    .canceled::before {
      background-color: #b753d3;
    }
    .delivered::before {
      background-color: #689e36;
    }

    .battery {
    }

    &.odd {
      background-color: #eef2f6;
    }

    td {
      border-left: 2px solid #d9d9d9;
    }
    .donor,
    .destination,
    .battery,
    .device {
      font-family: Inconsolata, monospace;
    }

    .details {
      min-width: 10rem;
      font-weight: bold;
      color: #006fba;
      border-left: none;
      cursor: pointer;
    }

    td:first-child {
      border-left: none;
    }
  }
  td.child-row {
    background-color: #f5f9ef !important;
  }
}

.dataTables_length {
  margin-left: 24px;
}

.dataTables_filter {
  margin-right: 24px;
}

.dataTables_info {
  margin-left: 24px;
}

.dataTables_paginate {
  margin-right: 24px;
}

.mapcard .mapboxgl-popup-tip {
  border: none;
  display: none;
}

.mapcard .mapboxgl-popup-content {
  background-color: transparent;
  box-shadow: none;
}

.satellite-view {
  background-color: white;
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}
